<nav class="navDefault">
    <div class="navDefault_container" md2-menu>
        <!-- <button (click)="openDialogExperimentalClass()" class="navDefault_container_button" mat-button md2-menu-trigger>AULA EXPERIMENTAL<span class="caret"></span></button> -->
        <button (click)="goTo()" class="navDefault_container_button" mat-button md2-menu-trigger>AULA EXPERIMENTAL<span class="caret"></span></button>
        <button (click)="showSubMenu()" class="navDefault_container_button first-btn" mat-button md2-menu-trigger>
            ESTUDIO <mat-icon>keyboard_arrow_down</mat-icon>
            <span class="caret"></span>
        </button>

        <button routerLink="/produtos" class="navDefault_container_button" mat-button md2-menu-trigger>PRODUTOS<span class="caret"></span></button>
        <button routerLink="/pacotes" class="navDefault_container_button" mat-button md2-menu-trigger>PACOTES<span class="caret"></span></button>
        <button routerLink="/agenda" class="navDefault_container_button" mat-button md2-menu-trigger>AGENDA<span class="caret"></span></button>
        <!--<button (click)="navigator('#contato')" class="navDefault_container_button" mat-button md2-menu-trigger>FALE CONOSCO<span class="caret"></span></button>-->
    </div>
</nav>

<aside class="manifest">
  <section class="manifest_top">
    <h1 class="manifest_top_title">{{title}}</h1>
    <p class="manifest_top_subtitle">{{subtitle}}</p>
  </section>
  <section class="manifest_content" fxLayout fxLayout.xs="column">
    <div fxFlex="50%" fxFlex.xs="auto" class="manifest_content_contentText_image" *ngIf="image" style.background-image="url({{image | ae2Thumbor:'700x'}})"></div>
    <div fxFlex="50%" fxFlex.xs="auto" class="manifest_content_contentText_image" *ngIf="!image" style.background-image="url('/assets/images/fallback.jpg')"></div>
    <div fxFlex="50%" fxFlex.xs="auto">
      <div class="manifest_content_contentText_text" [innerHTML]="description"></div>
      <!-- <button (click)="openDialog()" class="manifest_content_contentText_button" mat-raised-button color="accent">Agende Agora Sua Aula Experimental</button> -->
      <button (click)="goTo()" class="manifest_content_contentText_button" mat-raised-button color="accent">Agende Agora Sua Aula Experimental</button>
    </div>
  </section>
</aside>

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ae2ScrollWatcherService } from './scroll-watcher.service';

@NgModule({
  imports: []
})
export class Ae2ScrollWatcherServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: Ae2ScrollWatcherServiceModule,
      providers: [
        Ae2ScrollWatcherService
      ]
    };
  }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';
import { MatDialog } from '@angular/material';
import { ManifestDialogComponent } from '../../manifestdialog/manifestdialog.component';
import {
  Ae2HeaderLinkToSession,
  Ae2HeaderSubmenuState
} from '@angularecommerce/core/components/header-widget';

@Component({
  selector: 'app-menu-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.sass']
})
export class MenuDefaultComponent implements OnInit {

  subMenuStatus: boolean = false;

  @Output()
  onSubitemsShow: EventEmitter<Ae2HeaderSubmenuState> = new EventEmitter();

  constructor(
    private router: Router,
    private scrollService: ScrollToService,
    public dialog: MatDialog) {
  }
  ngOnInit(): void {
  }
  navigator(element: string): void {
    // this.router.navigate(['/']);
    const navigationExtras: NavigationExtras = {
      fragment: element
    };
    this.router.navigate(['/'], navigationExtras);
    setTimeout(() => {
      this.scrollService.scrollTo(`#${element}`);
    }, 1);
  }

  showSubMenu(): void {
    const links = [
      new Ae2HeaderLinkToSession('SOBRE', 'estudio'),
      new Ae2HeaderLinkToSession('ATIVIDADES', 'atividades'),
      new Ae2HeaderLinkToSession('INSTRUTORES', 'instrutores'),
      new Ae2HeaderLinkToSession('FALE CONOSCO', 'contato')
    ];
    this.onSubitemsShow.emit(new Ae2HeaderSubmenuState(
      true,
      links
    ));
    this.subMenuStatus = !this.subMenuStatus;
  }

  openDialogExperimentalClass(): void {
    const dialogRef = this.dialog.open(ManifestDialogComponent, {
    });
  }

  goTo(): void {
    window.open('http://aulaexperimental.bjjlab.com.br', '_self');
  }
}

import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-profile',
  template: '',
  styles: [
    `
    ::ng-deep .my-account {
      margin-bottom: -30px;
    }
    `
  ]
})
export class ProfileComponent implements OnInit {

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.host.nativeElement.appendChild(document.createElement('ae-my-account'));
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./profile.component";
var styles_ProfileComponent = [".my-account {\n      margin-bottom: -30px;\n    }"];
var RenderType_ProfileComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
export function View_ProfileComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ProfileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i0.ɵdid(1, 114688, null, 0, i1.ProfileComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i0.ɵccf("app-profile", i1.ProfileComponent, View_ProfileComponent_Host_0, {}, {}, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };

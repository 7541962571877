import { Component, OnInit } from '@angular/core';
import { Ae2CarouselConfig } from '@angularecommerce/core/components/carousel';

@Component({
    selector: 'app-list-units',
    templateUrl: './list-units.component.html',
    styleUrls: ['./list-units.component.sass']
})

export class ListUnitsComponent implements OnInit {
    carouselConfig: Ae2CarouselConfig;
    showImage: any = [];
    zoom: number = 16;
    customStyle: any = [
        {
            'featureType': 'administrative',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#444444'
                }
            ]
        },
        {
            'featureType': 'administrative.province',
            'elementType': 'labels',
            'stylers': [
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'administrative.locality',
            'elementType': 'labels',
            'stylers': [
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'administrative.neighborhood',
            'elementType': 'labels',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'landscape',
            'elementType': 'all',
            'stylers': [
                {
                    'color': '#f2f2f2'
                }
            ]
        },
        {
            'featureType': 'poi',
            'elementType': 'all',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'all',
            'stylers': [
                {
                    'saturation': -100
                },
                {
                    'lightness': 45
                }
            ]
        },
        {
            'featureType': 'road.highway',
            'elementType': 'all',
            'stylers': [
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'road.arterial',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'transit',
            'elementType': 'all',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'all',
            'stylers': [
                {
                    'color': '#fefefe'
                },
                {
                    'visibility': 'on'
                }
            ]
        }
    ];
    ngOnInit(): void {
        this.carouselConfig = new Ae2CarouselConfig();
        this.carouselConfig.useDottedOverLay = false;

        this.carouselConfig.bannerSize = {
            xs: '711x400',
            sm: '711x400',
            md: '711x400',
            lg: '711x400'
        };
      
        this.carouselConfig.ratios = {
            xs: '16:9',
            sm: '16:9',
            md: '16:9',
            lg: '16:9'
        };
    }

    testeData(id: any, a: any): void {
        if (a.count > 0) {
            setTimeout(() => {
                this.showImage[id] = false;
            }, 100);
        } else {
            setTimeout(() => {
                this.showImage[id] = true;
            }, 100);
        }
    }
}

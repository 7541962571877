import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ManifestDialogComponent } from '../manifestdialog/manifestdialog.component';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.sass']
})
export class ManifestComponent implements OnInit {

  @Input() title: string;

  @Input() subtitle: string;

  @Input() image: string;

  @Input() description: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ManifestDialogComponent, {
    });
  }

  goTo(): void {
    window.open('http://aulaexperimental.bjjlab.com.br', '_self');
  }


}

<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <ng-template ae2CarouselOverlayContent let-title="title" let-description="description" let-ctaLabel="ctaLabel"
        let-ctaUrl="ctaUrl">
        <div class="ae2Carousel__overlay-content-default">
            <h1 class="ae2Carousel__overlay-content-default__title" [innerHTML]="title"></h1>
            <h3 class="ae2Carousel__overlay-content-default__subtitle" [innerHTML]="description"></h3>
            <button class="ae2Carousel__overlay-content-default__button" mat-raised-button [routerLink]="ctaUrl">{{
                ctaLabel }}</button>
        </div>
        <ng-container *ngIf="ctaUrl == 'aula-experimental'">
            <div class="ae2Carousel-button">
                <button (click)="goTo()" class="ae2Carousel-button-btn" mat-button>AGENDAR AGORA</button>
            </div>
        </ng-container>
    </ng-template>
</ae2-carousel-container>

<ae2-article code="demian-maia">
    <ng-template ae2ArticleContent let-title="title" let-tagline="tagline" let-description="description" let-image="image">
        <app-manifest [title]="title" [subtitle]="tagline" [description]="description" [image]="image">
        </app-manifest>
    </ng-template>
</ae2-article>

<app-list-units></app-list-units>

<app-activities></app-activities>

<app-trainers></app-trainers>

<app-package></app-package>

<app-contact></app-contact>

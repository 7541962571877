<section class="activies">
    <aside class="activies_header">
        <h1 class="activies_header_title activies-adult_header_title">Atividades BJJ LAB</h1>
        <p class="activies_header_subtitle activies-adult_header_subtitle">
            Confira abaixo as atividades disponibilizadas por nossa equipe para você
        </p>
    </aside>

    <aside class="activies_container">
        <ae2-activity-list-container showInActivitiesPage="true" active="true">
            <ng-template #activityListTemplate let-results="results">
                <div class="activies_container_cads" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayoutGap.xs="0px">
                    <ng-container *ngFor="let activity of results">
                        <div class="activies_container_cads_mobileWidth"
                        fxFlex="calc(25% - 10px)"
                        fxFlex.xs="auto"
                        fxFlex.sm="auto">
                            <mat-card class="activies_container_cads_card"> 

                                <div class="activies_container_cads_card_image" *ngIf="activity.mediumImage" style.background-image="url({{activity.mediumImage | ae2Thumbor:'400x'}})"></div>
                                <div class="activies_container_cads_card_image" *ngIf="!activity.mediumImage" [ngStyle]="{'background': 'url(../assets/images/fallback.jpg)'}"></div>
                                <mat-card-content class="activies_container_cads_card_content">
                                    <h1 class="activies_container_cads_card_content_title">{{activity.name}}</h1>
                                    <div class="activies_container_cads_card_content_description" [innerHTML]="replace(activity.tagline | ae2HTMLReplace)"></div>
                                </mat-card-content>
                                <mat-card-content class="activies_container_cads_card_Color">
                                    <div class="activies_container_cads_card_Color_content">
                                        <h1 class="activies_container_cads_card_Color_content_title">{{activity.name}}</h1>
                                        <div class="activies_container_cads_card_Color_content_description" [innerHTML]="replace(activity.description | ae2HTMLReplace)"></div>
                                        <div fxLayoutAlign="end center">
                                            <button class="activies_container_cads_card_Color_content_button" mat-raised-button [routerLink]="['/atividades', activity.slug, activity.id]">Tudo sobre {{ activity.name }}</button>
                                        </div>
                                    </div>
                                </mat-card-content>

                            </mat-card>
                        </div>
                    </ng-container>
                </div>

            </ng-template>
        </ae2-activity-list-container>
    </aside>

</section>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Ae2ScrollWatcherServiceModule } from './shared/services/scroll-watcher/scroll-watcher.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import { Ae2AuthStoreModule } from '@angularecommerce/core/stores/auth';
import { Ae2HeaderStoreModule } from '@angularecommerce/core/stores/header';
import { Ae2UnitSelectorStoreModule } from '@angularecommerce/core/stores/unit-selector';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Ae2AuthVerificationInitializerModule } from '@angularecommerce/core/modules/auth-verification-initializer';
import { Ae2AngulareCheckoutModalStrategy, Ae2CheckoutStrategy } from '@angularecommerce/core/services/checkout-strategy';
import { FooterModule } from './shared/components/footer/footer.module';
import { HeaderModule } from './shared/components/header/header.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Ae2MyAccountGlobalConfig,
  AE2_MY_ACCOUNT_CONFIG_TOKEN
} from '@angularecommerce/core/components/my-account';
import { ProfileComponent } from './profile.component';
// import { Ae2CheckoutStrategy, Ae2AngulareCheckoutModalStrategy } from '@angularecommerce/core/services/checkout-strategy';

export interface MenuInterface {
  name: string;
  link: string;
  icon: string;
}

export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {
  // Meus dados
  showFormProfile: boolean = true;
  showFormPerformance: boolean = false;

  // Meus créditos
  linkPagePackage: string = '/pacotes';

  // Meus eventos
  linkPageEvent: string = '/atividades';

  // Menus
  menu: MenuInterface[] = [];

  // show FTP
  showFTP: boolean = true;


  // Titles
  titleProfile: string = 'Meus dados';
  titlePassword: string = 'Alterar minha senha';
  titleAdresses: string = 'Meus endereços';
  titleCards: string = 'Pagamento';
  titleOrders: string = 'Meus pedidos';
  titleSubscriptions: string = 'Minhas assinaturas';
  titleEvents: string = 'Meus eventos';
  titleFixed: string = 'Horário Fixo';
  titleCredits: string = 'Meus créditos';

  constructor() {
    super();

    this.menu.push({ name: 'Meus dados', link: 'meus-dados', icon: 'account_circle' });
    this.menu.push({ name: 'Alterar minha senha', link: 'alterar-senha', icon: 'lock' });
    this.menu.push({ name: 'Meus endereços', link: 'meus-enderecos', icon: 'room' });
    this.menu.push({ name: 'Pagamento', link: 'metodos-pagamento', icon: 'credit_card' });
    this.menu.push({ name: 'Meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' });
    this.menu.push({ name: 'Minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' });
    this.menu.push({ name: 'Meus eventos', link: 'meus-eventos', icon: 'event' });
    this.menu.push({ name: 'Horário Fixo', link: 'meus-eventos-automaticos', icon: 'event' });
    this.menu.push({ name: 'Meus créditos', link: 'meus-creditos', icon: 'account_balance' });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    CommonModule,

    HeaderModule,
    FooterModule,

    Ae2ScrollWatcherServiceModule.forRoot(),

    Ae2CoreModule,
    Ae2AuthVerificationInitializerModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    Ae2AuthStoreModule,
    Ae2HeaderStoreModule,
    Ae2UnitSelectorStoreModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig },
    { provide: Ae2CheckoutStrategy, useClass: Ae2AngulareCheckoutModalStrategy }
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

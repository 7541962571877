import { Component, OnInit } from '@angular/core';
import { Ae2CarouselConfig } from '@angularecommerce/core/components/carousel';

interface BannerSize {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
}

interface Ratios {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  carouselConfig: Ae2CarouselConfig;
  open: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    // const bannerSize: BannerSize = {
    //   xs: '375x',
    //   sm: '1024x',
    //   md: '1280x',
    //   lg: '1920x'
    // };
    // const reatios: Ratios = {
    //   xs: '16:9',
    //   sm: '4:3',
    //   md: '16:9',
    //   lg: '16:9'
    // };
    this.carouselConfig = new Ae2CarouselConfig();
    this.carouselConfig.useDottedOverLay = false;
    this.carouselConfig.intervalTime = 5000;
    this.carouselConfig.useAutoPlay = false;
    // this.carouselConfig.bannerSize = bannerSize;
    // this.carouselConfig.ratios = reatios;

  }
  openDialog(): void {
    this.open = true;
    setTimeout(() => {
      this.open = false;
    }, 100);
  }

  goTo(): void {
    window.open('http://aulaexperimental.bjjlab.com.br', '_self');
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angularecommerce/core/components/footer/footer.component.ngfactory";
import * as i3 from "@angularecommerce/core/components/footer/footer.component";
import * as i4 from "@angularecommerce/core/services/settings/settings.service";
import * as i5 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ae2-footer", [["logo", "assets/images/logo-footer.png"]], null, null, null, i2.View_Ae2FooterComponent_0, i2.RenderType_Ae2FooterComponent)), i1.ɵdid(1, 245760, null, 1, i3.Ae2FooterComponent, [i4.Ae2SettingsService], { logo: [0, "logo"] }, null), i1.ɵqud(335544320, 1, { templateRef: 0 })], function (_ck, _v) { var currVal_0 = "assets/images/logo-footer.png"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i5.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };

import { Routes, Resolve } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { of } from 'rxjs';
import { Ae2ActivitiesService, Ae2ActivitiesServiceGetAllQuery } from '@angularecommerce/core/services/activities';
import { map, tap } from 'rxjs/operators';
export var CALENDAR_RESOLVER_CACHE_KEY = 'calendar-filter-default-activity';
import { HomeComponent } from './features/home/home.component';
import { ProfileComponent } from './profile.component';
var CalendarResolver = /** @class */ (function () {
    function CalendarResolver(ae2ActivitiesService) {
        this.ae2ActivitiesService = ae2ActivitiesService;
    }
    CalendarResolver.prototype.resolve = function () {
        if (localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)) {
            return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)));
        }
        var query = new Ae2ActivitiesServiceGetAllQuery();
        query.active = true;
        query.use_as_filter = true;
        return this.ae2ActivitiesService.getAll(query)
            .getData$()
            .pipe(map(function (activities) { return activities.results[0]; }), tap(function (activity) { return localStorage.setItem(CALENDAR_RESOLVER_CACHE_KEY, JSON.stringify(activity)); }));
    };
    return CalendarResolver;
}());
export { CalendarResolver };
var ɵ0 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ1 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ7 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ8 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ9 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ10 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ11 = { someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ12 = { someProperty: AE2_ROUTER_CLASSES_DATA };
var routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        component: ProfileComponent,
        data: ɵ0
    },
    {
        path: 'agenda',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
        // data: { someProperty: AE2_ROUTER_CLASSES_DATA }
        resolve: {
            defaultActivity: CalendarResolver
        }
    },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ1
    },
    {
        path: 'atividades',
        loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
        data: ɵ2
    },
    {
        path: 'atividades/:slug/:id',
        loadChildren: 'app/features/activity/activity.module#ActivityModule',
        data: ɵ3
    },
    {
        path: 'atividades/tickets/:slug/:id',
        loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
        data: ɵ4
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ5
    },
    {
        path: 'instrutor/:slug/:id',
        loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
        data: ɵ6
    },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ7
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ8
    },
    {
        path: 'faq',
        loadChildren: 'app/features/faq/faq.module#FaqModule',
        data: ɵ9
    },
    {
        path: 'produtos',
        loadChildren: 'app/features/product/product.module#ProductModule',
        data: ɵ10
    },
    {
        path: 'pacotes',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ11
    },
    {
        path: 'aula-experimental',
        loadChildren: 'app/features/class/class.module#ClassModule',
        data: ɵ12
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpStepSignup, Ae2SignUpStepAddress, Ae2SignUpStepFitness, Ae2SignUpStepProfile } from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2HeaderWidgetConfig, Ae2HeaderLinkToSession, Ae2HeaderLinkToPage, Ae2HeaderSubmenu, Ae2HeaderMenu } from '@angularecommerce/core/components/header-widget';
import { ae2SamePasswordValidator } from '@angularecommerce/core/functions/same-password-validator';
var CustomAe2SignUpStepSignup = /** @class */ (function (_super) {
    __extends(CustomAe2SignUpStepSignup, _super);
    function CustomAe2SignUpStepSignup() {
        var _this = _super.call(this) || this;
        _this.fields = [
            new Ae2SignUpField({
                name: 'email',
                label: 'Email',
                validators: new Ae2SignUpFieldValidator({ required: true, validEmail: true, duplicateEmail: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'password',
                label: 'Senha',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'confirmPassword',
                label: 'Confirmar senha',
                validators: new Ae2SignUpFieldValidator({ type: Ae2SignupFieldTypes.PASSWORD, required: true, minlength: 6 }),
                row: 2,
                size: '48.5%'
            })
        ];
        _this.syncValidators = ae2SamePasswordValidator('password', 'confirmPassword');
        return _this;
    }
    return CustomAe2SignUpStepSignup;
}(Ae2SignUpStepSignup));
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.itens = [
            new Ae2HeaderLinkToSession('SOBRE', 'estudio'),
            new Ae2HeaderLinkToSession('ATIVIDADES', 'atividades'),
            new Ae2HeaderLinkToSession('INSTRUTORES', 'instrutores'),
            new Ae2HeaderLinkToSession('FALE CONOSCO', 'contato')
        ];
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.createSignupConfig();
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.myAccountRoute = '/minha-conta';
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderLinkToPage('AULA EXPERIMENTAL', '/aula-experimental'),
            new Ae2HeaderSubmenu('ESTUDIO', [
                new Ae2HeaderLinkToSession('SOBRE', 'estudio'),
                new Ae2HeaderLinkToSession('ATIVIDADES', 'atividades'),
                new Ae2HeaderLinkToSession('INSTRUTORES', 'instrutores'),
                new Ae2HeaderLinkToSession('FALE CONOSCO', 'contato')
            ]),
            new Ae2HeaderLinkToPage('PRODUTOS', '/produtos'),
            new Ae2HeaderLinkToPage('PACOTES', '/pacotes'),
            new Ae2HeaderLinkToPage('AGENDA', '/agenda')
        ];
        this.headerConfig.backgroundColor = '#fafafa';
        this.headerConfig.transparentOnRoutes = [];
    };
    HeaderComponent.prototype.createSignupConfig = function () {
        this.signupConfig = new Ae2SignUpStepperConfig([
            new CustomAe2SignUpStepSignup(),
            new Ae2SignUpStepProfile(),
            new Ae2SignUpStepAddress(),
            new Ae2SignUpStepFitness()
        ]);
    };
    return HeaderComponent;
}());
export { HeaderComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../menu/default/default.component.ngfactory";
import * as i3 from "../menu/default/default.component";
import * as i4 from "@angular/router";
import * as i5 from "ng2-scroll-to-el/scrollTo.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../../../node_modules/@angularecommerce/core/components/header-widget/header-layout-placement/header-layout-placement.component.ngfactory";
import * as i8 from "@angularecommerce/core/components/header-widget/header-layout-placement/header-layout-placement.component";
import * as i9 from "@ngrx/store";
import * as i10 from "../../../../../node_modules/@angularecommerce/core/components/header-widget/header-widget.component.ngfactory";
import * as i11 from "@angularecommerce/core/components/header-widget/header-widget.component";
import * as i12 from "@angularecommerce/core/services/settings/settings.service";
import * as i13 from "@angularecommerce/core/services/sticky-overlay-manager/sticky-overlay-manager.service";
import * as i14 from "@angular/flex-layout";
import * as i15 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-default", [], null, [[null, "onSubitemsShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubitemsShow" === en)) {
        var pd_0 = (_v.context.$implicit.submenu(_co.itens) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MenuDefaultComponent_0, i2.RenderType_MenuDefaultComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuDefaultComponent, [i4.Router, i5.ScrollToService, i6.MatDialog], null, { onSubitemsShow: "onSubitemsShow" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ae2-header-layout-placement-widget", [], [[2, "ae2-header-layout-placement-widget", null]], null, null, i7.View_Ae2HeaderLayoutPlacementWidgetComponent_0, i7.RenderType_Ae2HeaderLayoutPlacementWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i8.Ae2HeaderLayoutPlacementWidgetComponent, [i9.Store, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "ae2-header-widget", [], null, [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_Ae2HeaderWidgetComponent_0, i10.RenderType_Ae2HeaderWidgetComponent)), i1.ɵdid(3, 4440064, null, 10, i11.Ae2HeaderWidgetComponent, [i4.Router, i9.Store, i5.ScrollToService, i1.ChangeDetectorRef, i12.Ae2SettingsService, i13.Ae2StickyOverlayManagerService, i1.Renderer2, i14.ObservableMedia], { config: [0, "config"], signupConfig: [1, "signupConfig"] }, null), i1.ɵqud(335544320, 1, { headerTemplate: 0 }), i1.ɵqud(335544320, 2, { headerLoginTemplate: 0 }), i1.ɵqud(335544320, 3, { headerLogoTemplate: 0 }), i1.ɵqud(335544320, 4, { headerMenuTemplate: 0 }), i1.ɵqud(335544320, 5, { headerSidenavTemplate: 0 }), i1.ɵqud(335544320, 6, { headerSocialTemplate: 0 }), i1.ɵqud(335544320, 7, { headerSubmenuTemplate: 0 }), i1.ɵqud(335544320, 8, { headerUnitSelectorTemplate: 0 }), i1.ɵqud(335544320, 9, { signupTopTemplate: 0 }), i1.ɵqud(335544320, 10, { signupBottomTemplate: 0 }), (_l()(), i1.ɵand(0, [[4, 2], ["headerMenuTemplate", 2]], null, 0, null, View_HeaderComponent_1))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.headerConfig; var currVal_2 = _co.signupConfig; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i15.HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i15.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuDefaultComponent } from './default.component';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { ManifestDialogModule } from '../../manifestdialog/manifestdialog.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    ScrollToModule,
    ManifestDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [MenuDefaultComponent],
  exports: [MenuDefaultComponent]
})
export class MenuDefaultModule { }

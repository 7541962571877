import { NgModule } from '@angular/core';
import { ManifestComponent } from './manifest.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';
import { ManifestDialogModule } from '../manifestdialog/manifestdialog.module';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    Ae2ThumborModule,
    ManifestDialogModule,
    MatButtonModule
  ],
  declarations: [ManifestComponent],
  exports: [ManifestComponent]
})
export class ManifestModule { }

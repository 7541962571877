/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./package.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "./package.component";
var styles_PackageComponent = [i0.styles];
var RenderType_PackageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PackageComponent, data: {} });
export { RenderType_PackageComponent as RenderType_PackageComponent };
export function View_PackageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "packages-home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "package-home-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "package-home-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "package-home-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Conhe\u00E7a nossos Pacotes"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "package-home-button"], ["mat-raised-button", ""], ["routerLink", "/pacotes"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["SAIBA MAIS"]))], function (_ck, _v) { var currVal_2 = "/pacotes"; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 7).disabled || null); var currVal_1 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_PackageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-package", [], null, null, null, View_PackageComponent_0, RenderType_PackageComponent)), i1.ɵdid(1, 49152, null, 0, i8.PackageComponent, [], null, null)], null, null); }
var PackageComponentNgFactory = i1.ɵccf("app-package", i8.PackageComponent, View_PackageComponent_Host_0, {}, {}, []);
export { PackageComponentNgFactory as PackageComponentNgFactory };

<ae2-contact-form code="contato" subject="'Agendar Aula Experimental'" [fields]="{
                name: { required: true },
                email: { required: true, validEmail: true },
                phone: { required: true },
                subject: { required: true},
                message: { required: false }
            }">

    <ng-template #contactFormTemplate let-form="form" let-methods="methods">
        <div class="c-t">
            <ae2-site-settings>
                <ng-template ae2SiteSettingsContent let-site="site">
                    <div class="c-t-o">Ligue agora mesmo</div>
                    <div class="c-t-t">{{ site.phone }}</div>
                    <div class="c-t-t">{{ site.mobile }}</div>
                    <div class="c-t-tr">ou</div>
                    <div class="c-t-f">Preencha os campos abaixos que nossa equipe entrará em contato com você!</div>
                </ng-template>
            </ae2-site-settings>
        </div>
        <form [formGroup]="form" (submit)="methods.submit($event)">
            <div class="c-f">
                <mat-form-field class="c-f-i">
                    <input matInput placeholder="Nome" formControlName="name">
                </mat-form-field>
                <ae2-control-messages [control]="form.get('name')"></ae2-control-messages>
            </div>

            <div class="c-f">
                <mat-form-field class="c-f-i">
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>
                <ae2-control-messages [control]="form.get('email')"></ae2-control-messages>
            </div>

            <div class="c-f">
                <mat-form-field class="c-f-i">
                    <input matInput placeholder="Celular" ae2Mask="(99) 99999 9999" [specialCharacters]="true"
                        formControlName="phone">
                </mat-form-field>
                <ae2-control-messages [control]="form.get('phone')"></ae2-control-messages>
            </div>
            <div class="c-f">
                <mat-form-field class="c-f-i">
                    <mat-select placeholder="Tipo de aula desejada" formControlName="subject">
                        <mat-option value="Jiu Jitsu">
                            Jiu Jitsu
                        </mat-option>
                        <mat-option value="Funcional Fight">
                            Funcional Fight
                        </mat-option>
                        <mat-option value="Muay Thai">
                            Muay Thai
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <ae2-control-messages [control]="form.get('subject')"></ae2-control-messages>
            </div>


            <div class="c-f" fxLayout fxLayoutAlign="space-between center">
                <button type="button" (click)="onNoClick()" mat-raised-button>CANCELAR</button>
                <button type="submit" (click)="onNoClick()" mat-raised-button [disabled]="form.invalid">ENVIAR</button>
            </div>
        </form>
    </ng-template>
</ae2-contact-form>
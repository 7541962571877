<ae2-unit-list-container>
  <ng-template #unitListTemplate let-results="results">
    <section class="section" *ngIf="results.length > 0">
      <aside class="unit">

        <div *ngFor="let item of results; let i = index" class="unit-content" fxLayout="column">

          <h2 [innerHtml]="item.name" class="md2-accordion-header"></h2>

          <div class="unit_container" fxLayout fxLayout.xs="column" fxLayout.sm="column">
            <div class="unit_container_item" fxFlex="45%" fxFlex.xs="auto" fxFlex.sm="auto">
              <ae2-carousel-container (data)="testeData(item.id, $event)" [carouselCode]="item.slug" [config]="carouselConfig" *ngIf="item.slug">
                <ng-template ae2CarouselOverlayContent let-title="title" let-description="description" let-ctaLabel="ctaLabel" let-ctaUrl="ctaUrl"
                  let-desktopImage="desktopImage">
                  <div class="ae2Carousel__overlay-content-default">&nbsp;</div>
                </ng-template>
              </ae2-carousel-container>
            </div>
            <div class="unit_container_item" fxFlex="55%" fxFlex.xs="auto" fxFlex.sm="auto">
              <div class="unit_container_item_description" [innerHTML]="item.description"></div>
              <div class="unit_container_item_events">
              </div>
            </div>
          </div>

          <div class="map" *ngIf="item.latitude && item.longitude">
            <div class="boxMap" fxLayout>
              <div class="boxMap_pin" fxFlex="20%"></div>
              <div class="boxMap_box" fxFlex="80%">
                <div class="boxMap_box_title">Onde estamos</div>
                <div class="boxMap_box_address" [innerHTML]="item.address"></div>
              </div>
            </div>
            <agm-map [latitude]="item.latitude" [longitude]="item.longitude" [zoom]="zoom" [styles]="customStyle" [scrollwheel]="false">
              <agm-marker [latitude]="item.latitude" [longitude]="item.longitude">
              </agm-marker>
            </agm-map>
          </div>

          <div fxFlex="auto" fxLayoutAlign="space-between center">
            <ae2-event-list-container fxFlex="auto" sort="start_time" pageSize="5" nextEvents="true" [unitList]="[item.id]" [useLazyLoading]="true"
              [useLoadAllPages]="false">
              <ng-template let-events="results" let-eventBooks="eventBooks" let-eventAutoBooks="eventAutoBooks">

                <h2 *ngIf="events.length > 0" class="unit_container_item_events_title">Próximas aulas</h2>
                <div fxLayout.sm="row wrap" fxLayout="row wrap" fxLayout.xs="column" *ngIf="events.length > 0" class="unit_container_item_events_wrapper">
                  <ng-container *ngFor="let event of events">

                    <ae2-event-widget fxFlex.sm="50%" [event]="event" [autoBooks]="eventAutoBooks" [eventBooks]="eventBooks" class="unit_container_item_events_card">
                    </ae2-event-widget>

                  </ng-container>

                </div>

                <button class="buttonAgenda" [routerLink]="['/agenda']" mat-raised-button>AGENDA COMPLETA</button>

              </ng-template>
            </ae2-event-list-container>

          </div>
        </div>
      </aside>
    </section>
  </ng-template>
</ae2-unit-list-container>
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ManifestDialogComponent } from './manifestdialog.component';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';
import { Ae2ContactFormModule } from '@angularecommerce/core/components/contact-form';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { Ae2MaskModule } from '@angularecommerce/core/directives/mask';
import { MatInputModule, MatButtonModule, MatSelectModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    Ae2ThumborModule,
    Ae2ContactFormModule,
    Ae2SiteSettingsModule,
    Ae2MaskModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule
  ],
  declarations: [ManifestDialogComponent],
  exports: [ManifestDialogComponent],
  entryComponents: [ManifestDialogComponent]
})
export class ManifestDialogModule { }
